<script>
export default {
  data() {
    return {
      is_active: false
    }
  }
}
</script>

<template>
  <header>
    <nav class="navbar has-shadow">
      <div class="navbar-brand">
        <a class="navbar-item" href="#">
          <strong>Mustsee</strong>
        </a>

        <a
          class="navbar-burger"
          @click="is_active = !is_active"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div class="navbar-menu" :class="{'is-active': is_active}">
        <div class="navbar-end">
          <div class="navbar-item">
            <router-link class="button is-primary" :to="{name: 'places_add'}">
              Add Place
            </router-link>
          </div>
          <div class="navbar-item">
            <router-link class="button is-primary" :to="{name: 'places_list'}">
              List Places
            </router-link>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>
