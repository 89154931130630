import Vue from 'vue'

export const state = {
  is_logged: null
}

Vue.mixin({
  data() {
    return {
      store: state
    }
  }
})
