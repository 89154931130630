import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {auth: true},
      component: require('/src/pages/Home').default
    },
    {
      path: '/login',
      name: 'login',
      meta: {auth: false},
      component: () => import('/src/pages/Login')
    },
    {
      path: '/places/list',
      name: 'places_list',
      meta: {auth: true},
      component: () => import('/src/pages/PlacesList')
    },
    {
      path: '/places/add',
      name: 'places_add',
      meta: {auth: true},
      component: () => import('/src/pages/PlacesAdd')
    }
  ]
})

export default router
