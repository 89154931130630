<script>
export default {
}
</script>

<template>
  <section class="section">
    home
  </section>
</template>
