// The Vue build version to load with the `import` command
import Vue from 'vue'
import Buefy from 'buefy'

import './config/store'
import './config/firebase'
import router from './config/router'
import App from './App'

Vue.use(Buefy)

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
