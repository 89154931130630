<script>
import { auth } from '/src/config/firebase'
import TheHeader from '/src/components/TheHeader'

export default {
  name: 'App',
  components: {
    TheHeader
  },

  methods: {
    middleware(to, next) {
      if (!next) {
        next = this.$router.push.bind(this.$router)
      }

      if (to.matched.some(record => record.meta.auth === true) && !this.store.is_logged) {
        next({name: 'login'})
        return true
      } else if (to.matched.some(record => record.meta.auth === false) && this.store.is_logged) {
        next({name: 'home'})
        return true
      }

      return false
    }
  },

  created() {
    console.log('created')
    const auth$ = auth.onAuthStateChanged(user => {
      console.log('user')
      this.store.is_logged = !!user
      auth$()

      this.$router.beforeEach((to, from, next) => {
        if (this.middleware(to, next)) {
          return
        }

        next()
      })

      this.middleware(this.$route)
    })
  }
}
</script>

<template>
  <div id="app">
    <the-header></the-header>

    <div class="container" v-if="store.is_logged != null">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
@import "~bulma";
@import "~buefy/src/scss/buefy";

html {
  background: $light;
}

.container {
  max-width: 960px;
}
</style>
