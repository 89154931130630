import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

export const config = {
  apiKey: 'AIzaSyDk5pjKo7qgbpv6v6JLOWJm16TB-DJG7VQ',
  authDomain: 'mustsee-earth.firebaseapp.com',
  databaseURL: 'https://mustsee-earth.firebaseio.com',
  projectId: 'mustsee-earth',
  storageBucket: 'mustsee-earth.appspot.com',
  messagingSenderId: '753060967772'
}

firebase.initializeApp(config)

export const auth = firebase.auth()
export const database = firebase.database()
export const storage = firebase.storage()
